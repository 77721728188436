




































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import AdjustmentTextInput from '../../components/AdjustmentTextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import {
  EnumTransactionParcelPoinRequest,
  EventBusConstants,
  MODAL_CONFIRM_REQUEST_POIN,
  MODAL_SUCCESS_REQUEST_POIN,
  REQUEST_TOP_SALDO_PAGINATION,
  Utils,
} from '@/app/infrastructures/misc'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import ModalConfirm from '../../components/AdjustmentModals/ModalConfirm/index.vue'
import ModalDetail from '../../components/AdjustmentModals/ModalDetail/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import { Validations } from 'vuelidate-property-decorators'
import {
  maxLength,
  maxValue,
  minLength,
  minValue,
  required,
  integer,
  ValidationRule,
} from 'vuelidate/lib/validators'
import {
  MAX_NOMINAL_REQUEST_POIN,
  MAX_NOTES_REQUEST_TOPUP,
} from '@/app/infrastructures/misc/Constants/validation'
import controller, {
  HistoryLogUserParamsInterface,
} from '@/app/ui/controllers/AdjustmentParcelPoinController'
import { HistoryLogPoinUser } from '@/domain/entities/AdjustmentParcelPoin'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { validationMixin } from 'vuelidate'
import { Dictionary } from 'vue-router/types/router'

/**
 * interface for form state
 */
export interface formPoin {
  poin: number | null
  note: string
  transaction: EnumTransactionParcelPoinRequest
}

export interface ValidationsInterface {
  form: {
    poin: {
      required: () => ValidationRule,
      minValue: ValidationRule,
      maxValue: ValidationRule,
      integer: () => ValidationRule,
    },
    note: {
      required: () => ValidationRule,
      minLength: ValidationRule,
      maxLength: ValidationRule,
    },
    transaction: {
      required: () => ValidationRule,
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    AdjustmentTextInput,
    Button,
    RadioButton,
    DataTableV2,
    PaginationNav,
    ModalConfirm,
    ModalDetail,
    Modal,
    LoadingOverlay,
  },
})
export default class RequestPoinDetail extends Vue {
  form: formPoin = {
    poin: null,
    note: '',
    transaction: EnumTransactionParcelPoinRequest.CREDIT,
  }
  parameters = {
    page: 1,
    perPage: REQUEST_TOP_SALDO_PAGINATION,
    customerId: 0,
    sortBy: 'NEWEST',
  }
  headers: Array<Record<string, string | Record<string, string>>> = [
    {
      title: 'Date',
      customStyle: { minWidth: '220px', maxWidth: '220px' },
    },
    {
      title: 'Request Poin',
      customStyle: { minWidth: '125px', maxWidth: '125px' },
    },
    {
      title: 'Requestor',
      customStyle: { minWidth: '140px', maxWidth: '140px' },
    },
    {
      title: 'Approval',
      customStyle: { minWidth: '140px', maxWidth: '140px' },
    },
    {
      title: 'Notes',
      customStyle: { minWidth: '155px', maxWidth: '155px' },
    },
    {
      title: 'Status',
      customStyle: { minWidth: '110px', maxWidth: '110px' },
    },
  ]
  showTable = true
  modalConfirm = false
  modalSuccess = false
  modalDetail = false
  currentNotes = ''
  MODAL_SUCCESS_REQUEST_POIN = MODAL_SUCCESS_REQUEST_POIN
  MODAL_CONFIRM_REQUEST_POIN = MODAL_CONFIRM_REQUEST_POIN
  isSuperAdmin = Utils.isSuperAdmin()
  controller = controller
  EnumTransactionParcelPoinRequest = EnumTransactionParcelPoinRequest

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: REQUEST_TOP_SALDO_PAGINATION,
        customerId: 0,
        sortBy: 'NEWEST',
      }
    }
    // fetch history
    this.fetchHistoryLogPoinUser()
  }

  @Watch('controller.statusRequestParcelPoin')
  onStatusRequestParcelPoinChanged(status: string): void {
    if (status !== '' && status === EventBusConstants.ADJUSTMENT_PARCEL_POIN_REQUEST_SUCCESS) {
      this.modalConfirm = false
      this.modalSuccess = true
    }
    controller.setStatusRequestParcelPoin('')
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        poin: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(MAX_NOMINAL_REQUEST_POIN),
          integer,
        },
        note: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(MAX_NOTES_REQUEST_TOPUP),
        },
        transaction: {
          required,
        },
      },
    }
  }

  /**
   * get phone number for history log poin user
   */
  get phoneNumberUser(): string {
    return this.controller.phoneNumber ? Utils.countryIndonesiaPhoneNumber(this.controller.phoneNumber, true) : ''
  }

  /**
   * function toggle showTable
   */
  private onShowHide(): void {
    this.showTable = !this.showTable
  }

  /**
   * data items for DataTableV2
   */
  get dataRequestPoinHistoryList(): Array<DataObject[]> {
    if (this.controller.listHistoryLogPoinUser.length > 0) {
      return this.controller.listHistoryLogPoinUser.map(
        (item: HistoryLogPoinUser) => {
          return [
            {
              value: item.updatedAt ? this.getDate(item.updatedAt) : item.createdAt ? this.getDate(item.createdAt) : '',
              customStyle: { minWidth: '220px', maxWidth: '220px' },
            },
            {
              value: `${
                Number(item.delta) > 0
                  ? `+${Utils.currencyDigit(Number(item.delta))}`
                  : Utils.currencyDigit(Number(item.delta))
              }`,
              customStyle: { minWidth: '125px', maxWidth: '125px' },
            },
            {
              value: item.requester,
              customStyle: { minWidth: '140px', maxWidth: '140px' },
            },
            {
              value: item.approval || '-',
              customStyle: { minWidth: '140px', maxWidth: '140px' },
            },
            {
              value: item.note || '-',
              customStyle: { minWidth: '155px', maxWidth: '155px' },
            },
            {
              value: Utils.toCapitalize(item.status || '-'),
              customStyle: { minWidth: '110px', maxWidth: '110px' },
            },
          ]
        }
      )
    }
    return []
  }

  private getDate(date: string): string {
    return Utils.formatTimeZone(
      Utils.formatDate(date, 'DD MMM YYYY, HH:mm:ss Z')
    )
  }

  private onSubmit(): void {
    if (!this.$v.form.$invalid) {
      this.controller.postRequestPoin({
        customerId: Number(this.$route.params.id),
        delta: Number(this.form.poin),
        note: this.form.note,
        pointType: this.form.transaction,
      })
    } else {
      this.$notify({
        title: 'Request Failed',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  private seeNotes(index: number): void {
    this.modalDetail = true
    this.currentNotes = <string>this.dataRequestPoinHistoryList[index][4].value
  }

  get params(): HistoryLogUserParamsInterface {
    return {
      ...this.parameters,
      customerId: Number(this.$route.params.id),
    }
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    if (!this.isSuperAdmin) {
      this.$router.push({ name: 'AdjustmentParcelPoinRequestPoinPage' })
    }
    this.$router
      .replace({
        query: { ...val },
      })
      .catch(() => false)
  }

  private fetchHistoryLogPoinUser(): void {
    this.controller.getHistoryLogPoinUser(this.params)
  }

  beforeDestroy(): void {
    controller.setStatusRequestParcelPoin('')
  }
}
